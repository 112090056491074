<script setup lang="ts">
import type { Placement } from '@floating-ui/dom'
import type { DatePickerRange } from '@rialtic/types'
import isAfter from 'date-fns/isAfter'
import { datePresets, sinceInceptionDate } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

const workspace = useWorkspace()
const { presets, getPresetByName, currentPresetDate } = useDatePresets()

const props = defineProps<{
  placement: Placement
  dateRange: DatePickerRange
}>()

const emit = defineEmits(['date-change'])

const startDate = ref(props.dateRange.start || '')
const endDate = ref(props.dateRange.end || '')
const activePreset = ref(getPresetByName(props.dateRange.preset))
const activeDropDownItem = computed(() => {
  return typeof activePreset.value.itemValue === 'object'
    ? activePreset.value.itemValue
    : ({} as DatePickerRange)
})

const { todayDate, today } = useClock()

const isCustomPreset = computed(() => {
  return activePreset.value && activePreset.value.label === datePresets.custom
})

const cursorClass = computed(() =>
  isCustomPreset.value ? 'cursor-pointer' : '',
)

watch(todayDate, () => {
  currentPresetDate.value = new Date(today.value)
})

debouncedWatch(
  [startDate, endDate],
  ([newStartDate, newEndDate]) => {
    const end = new Date(newEndDate)
    if (isAfter(new Date(newStartDate), end)) {
      startDate.value = newEndDate
      endDate.value = newStartDate
      return
    }

    emit('date-change', {
      start: newStartDate,
      end: newEndDate,
      preset: activeDropDownItem.value.preset || datePresets.custom,
    } as DatePickerRange)
  },
  {
    debounce: 1000,
  },
)

debouncedWatch(
  activePreset,
  () => {
    // custom preset is set by user
    if (isCustomPreset.value) return

    startDate.value = activeDropDownItem.value.start
    endDate.value = activeDropDownItem.value.end
  },
  {
    immediate: true,
  },
)

const setCustomMode = (): void => {
  if (isCustomPreset.value) return

  activePreset.value = getPresetByName(datePresets.custom)
}
</script>

<template>
  <section class="flex h-9">
    <UiDropdown
      v-model="activePreset"
      class="w-37"
      :items="presets"
      :placement="placement"
      button-border-radius="rounded-l-lg"
      data-testid="timeFrame_preset-date"
    />
    <div
      class="bg-surface-bg leading-0 relative -left-[1px] mx-0 flex items-center rounded-r-lg border border-neutral-200 px-3 py-2 text-neutral-800"
    >
      <input
        v-model="startDate"
        class="date-picker w-25 subtitle-2"
        :class="cursorClass"
        :max="endDate"
        :min="sinceInceptionDate"
        aria-label="Start Date"
        type="date"
        data-testid="timeFrame_start-date"
        @focus="setCustomMode"
      />
      <span class="relative mx-2">&ndash;</span>
      <input
        v-model="endDate"
        class="date-picker w-25 subtitle-2"
        :class="cursorClass"
        :max="todayDate"
        :min="startDate"
        aria-label="End Date"
        type="date"
        data-testid="timeFrame_end-date"
        @focus="setCustomMode"
      />
    </div>
  </section>
</template>

<style scoped>
.date-picker {
  background: transparent url(../assets/date-picker.svg) 97% 50% no-repeat;
}
.date-picker::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.date-picker.cursor-pointer::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
</style>
